/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    Row,
    Table
} from "reactstrap";

// plugins
import axios from "axios";
import swal from '@sweetalert/with-react';
import moment from 'moment';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import GlobalFunctions from "../controllers/GlobalFunctions";
import API_URL from '../variables/API_URL';

import {tbody, thead} from "variables/general";

class CorrectiveActionFilled extends React.Component {

    state = {
        incidents: [],
        globalData: [],
        showViewModal: false
    }

    //RUNS WHEN COMPONENT IS MOUNTED
    componentDidMount = () => {
        // this.getIncidents();
        this.calculateWeekRange();

    }

    //HANDLE INPUT VALUES
    inputValue = (event) => {
        this.setState({[event.name]: event.value})
    }


    //GET ALL INCIDENTS FROM DATABASE
    getIncidents = () => {
        var url = `${API_URL.url}/corectiveActions/all?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
        axios.get(url).then(incident => {
            this.setState({incidents: incident.data, globalData: incident.data});
            console.log(incident.data);
        }).catch(error => {
            console.log(error);
        })
    }

    getIncidentsFilteredByDate = (event) => {

        var url = `${API_URL.url}/corectiveActions/range?start=${event.weekStart}&end=${event.weekEnd}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;

        axios.get(url).then(incident => {

            this.setState({incidents: incident.data, globalData: incident.data});

        }).catch(error => {

        })
    }

    handleDelete = (event) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, record will not be recovered!  ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var url = `${API_URL.url}/corectiveActions/delete?id=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
                    axios.post(url).then(incident => {
                        this.getIncidents();
                        swal("Incident Record is deleted successfully!", "", "success");
                    }).catch(error => {
                        let errorObject = JSON.parse(JSON.stringify(error));
                        swal(`${errorObject.message}!`, "Failed to delete, click okay and try again!", "error")
                            .then(() => {
                            })
                    })

                }
            })
    }

    handleSignOut = (event) => {
        swal({
            title: "Are you sure?",
            text: "Once closed, it can't be undone!  ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    var url = `${API_URL.url}/incidents/close?id=${event}&TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;
                    axios.post(url).then(incident => {
                        this.calculateWeekRange();
                        console.log(incident);
                        swal("Incident Record is closed successfully!", "", "success");
                    }).catch(error => {
                        let errorObject = JSON.parse(JSON.stringify(error));
                        swal(`${errorObject.message}!`, "Failed to close incident, click okay and try again!", "error")
                            .then(() => {
                            })
                    })

                }
            })
    }


    handleChange = event => {
        this.setState({searchInput: event.target.value}, () => {
            this.globalSearch();
        });
    };

    globalSearch = () => {
        let {searchInput} = this.state;
        let filteredData = this.state.globalData.filter(value => {
            console.log(value.tbluser.firstname);
            return (
                (
                    (value.tbluser ? value.tbluser.firstname.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
                    (value.tbluser ? value.tbluser.lastname.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
                    (value.tbluser ? value.tbluser.lastname.toLowerCase().includes(searchInput.toLowerCase()) : false)
                )
            );
        });
        this.setState({incidents: filteredData});
    };

    calculateWeekRange = () => {
        let now = moment();
        var day = Number(now.weekday())
        // var start = now.subtract(day, 'days').format('MM-DD-YYYY');
        // var end = now.add(6, 'days').format('MM-DD-YYYY')

        const start = moment().startOf('month').format('MM-DD-YYYY');
        const end = moment().endOf('month').format('MM-DD-YYYY');

        this.setState({weekStart: start, weekEnd: end})
        this.getIncidentsFilteredByDate({weekStart: start, weekEnd: end});

        console.log(this.state.weekStart);

    }

    getNextWeek = () => {
        // var start = moment(this.state.weekEnd).format('MM-DD-YYYY');
        // start = moment(start).add(1, 'days').format('MM-DD-YYYY');
        // var end = moment(start).add(6, 'days').format('MM-DD-YYYY')

        var start = moment(this.state.weekEnd).add(1, "days").format('MM-DD-YYYY');
        var end = moment(start).endOf('month').format('MM-DD-YYYY');

        this.setState({weekStart: start, weekEnd: end})
        this.getIncidentsFilteredByDate({weekStart: start, weekEnd: end});

    }

    getPreviousWeek = () => {
        // var end = moment(this.state.weekStart).format('MM-DD-YYYY');
        // end = moment(end).subtract(1, 'days').format('MM-DD-YYYY');
        // var start = moment(end).subtract(6, 'days').format('MM-DD-YYYY');

        var end = moment(this.state.weekStart).subtract(1, "days").format('MM-DD-YYYY');
        var start = moment(end).startOf('month').format('MM-DD-YYYY');


        this.setState({weekStart: start, weekEnd: end})
        this.getIncidentsFilteredByDate({weekStart: start, weekEnd: end});

    }


    render() {
        return (
            <>
                <Modal isOpen={this.state.showViewModal}
                       toggle={() => this.setState({showViewModal: false})}
                       size="xl">
                    <Form onSubmit={this.getIncidents}>
                        <div
                            className="modal-header justify-content-center">
                            <button
                                className="close"
                                type="button"
                                onClick={() => this.setState({showViewModal: false})}
                            >
                                <i className="now-ui-icons ui-1_simple-remove"></i>
                            </button>
                            <h4 className="title title-up">View filled
                                corective
                                action {this.state.selectedCorrectiveActionId}</h4>
                        </div>
                        <ModalBody style={{
                            "max-height": "calc(100vh - 210px)",
                            "overflow-y": "auto"
                        }}>

                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>ltem</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{this.state.selectedItem}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>
                                    <strong>Issue/Problem/Recommendation</strong>
                                </li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{this.state.selectedIssue}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>Proposed Corrective
                                    Action</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{this.state.selectedProposedCorrectiveAction}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>Priority</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{
                                    this.state.selectedPriority == "insignificant" ?
                                        <Badge id="badgeInsignificant" color="success"
                                               style={{marginLeft: -20}}>Insignificant</Badge>
                                        : this.state.selectedPriority == "Minor" ?
                                            <Badge id="badgeMinor" color="success"
                                                   style={{marginLeft: -20}}>Minor</Badge>
                                            : this.state.selectedPriority == "Moderate" ?
                                                <Badge id="badgeModerate" color="warning"
                                                       style={{marginLeft: -20}}>Moderate</Badge>
                                                : this.state.selectedPriority == "Major" ?
                                                    <Badge id="badgeMajor" color="warning"
                                                           style={{marginLeft: -20}}>Major</Badge>
                                                    : this.state.selectedPriority == "Critical" ?
                                                        <Badge id="badgeCritical" color="danger"
                                                               style={{marginLeft: -20}}>Critical</Badge>
                                                        : this.state.selectedPriority}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>Risk rating</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{
                                    this.state.selectedRiskRating == "Low" ?
                                        <Badge id="badgeRiskLow" color="success">Low</Badge>
                                        : this.state.selectedRiskRating == "Medium" ?
                                            <Badge id="badgeRiskMedium"
                                                   color="success">Medium</Badge>
                                            : this.state.selectedRiskRating == "High" ?
                                                <Badge id="badgeRiskHigh"
                                                       color="warning">High</Badge>
                                                : this.state.selectedRiskRating == "Extreme" ?
                                                    <Badge id="badgeRiskExtreme"
                                                           color="danger">Extreme</Badge>
                                                    : this.state.selectedRiskRating}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>Person Responsible for
                                    follow-up</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{this.state.selectedPersonResponsible}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>Nominated Completion
                                    Date</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{this.state.selectedCompletionDate}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>Status</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{this.state.selectedStatus}</li>
                            </ul>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}><strong>Outcome Evaluation</strong></li>
                                <li class="list-group-item" style={{
                                    "width": "50%",
                                    "textAlign": "center"
                                }}>{this.state.selectedOutcomeEvaluation}</li>
                            </ul>

                            {/*

                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Site location</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentLocation}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Contacted</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentContacted}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Date</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentDate}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>witnesses</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>
                                          <ul class="list-group list-group-horizontal">
                                            <li class="list-group-item" style={{
                                              "width": "35%",
                                              "textAlign": "center"
                                            }}><strong>Name</strong></li>
                                            <li class="list-group-item" style={{
                                              "width": "35%",
                                              "textAlign": "center"
                                            }}><strong>Phone</strong></li>
                                            <li class="list-group-item" style={{
                                              "width": "35%",
                                              "textAlign": "center"
                                            }}><strong>Email</strong></li>
                                          </ul>

                                          {
                                            this.state.selectedIncidentWitnesses.map(element => {
                                              return (

                                                <ul class="list-group list-group-horizontal">
                                                  <li class="list-group-item" style={{
                                                    "width": "35%",
                                                    "textAlign": "center"
                                                  }}>{element.name}</li>
                                                  <li class="list-group-item" style={{
                                                    "width": "35%",
                                                    "textAlign": "center"
                                                  }}>{element.phone}</li>
                                                  <li class="list-group-item" style={{
                                                    "width": "35%",
                                                    "textAlign": "center"
                                                  }}>{element.email}</li>
                                                </ul>

                                              )
                                            })}

                                        </li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Description of what happened before the incident</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentDescriptionBefore}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Description of what happened during the incident</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentDescriptionDuring}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Description of what happened after the incident</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentDescriptionAfter}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Incident category</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentCategory}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Impact assessment</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentIdImpactAssessment}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Reportable</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentIsReportable}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Initial actions taken</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentInitialActionTaken}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Police reference number</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentPoliceReferenceNumber}</li>
                                      </ul>
                                      <ul class="list-group list-group-horizontal">
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}><strong>Practice leader</strong></li>
                                        <li class="list-group-item" style={{
                                          "width": "50%",
                                          "textAlign": "center"
                                        }}>{this.state.selectedIncidentPractiseLeader}</li>
                                      </ul> */}
                        </ModalBody>
                        <div className="modal-footer">
                            {/* <Button disabled className="btn-round" color="info" type="submit" value="Submit">
                                        Download PDF Form
                                      </Button> */}
                            <Button
                                color="danger"
                                type="button"
                                onClick={() => this.setState({showViewModal: false})}
                            >
                                Close
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <PanelHeader size="sm"/>
                <div className="content">
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <Row>

                                        <div class="col-md-12">
                                            <nav class="navbar navbar-expand-lg bg-info">
                                                <div class="container">
                                                    <a class="navbar-brand" href="#">Monthly Filled out corrective
                                                        actions</a>
                                                    <button class="navbar-toggler" type="button" data-toggle="collapse"
                                                            data-target="#example-navbar-icons"
                                                            aria-controls="navbarSupportedContent" aria-expanded="false"
                                                            aria-label="Toggle navigation">
                                                        <span class="navbar-toggler-bar bar1"></span>
                                                        <span class="navbar-toggler-bar bar2"></span>
                                                        <span class="navbar-toggler-bar bar3"></span>
                                                    </button>
                                                    <div class="collapse navbar-collapse" id="example-navbar-icons">
                                                        <ul class="navbar-nav ml-auto">
                                                            <li class="nav-item">
                                                                <a class="nav-link" onClick={this.getPreviousWeek}><i
                                                                    class="now-ui-icons arrows-1_minimal-left"
                                                                    aria-hidden="true"></i></a>
                                                            </li>
                                                            <li class="nav-item active">
                                                                <a class="nav-link" href="#">
                                                                    <p>{moment(this.state.weekStart).format('ddd') + ', ' + moment(this.state.weekStart).format('DD') + ' ' + moment(this.state.weekStart).format('MMM')} - {moment(this.state.weekEnd).format('ddd') + ', ' + moment(this.state.weekEnd).format('DD') + ' ' + moment(this.state.weekEnd).format('MMM')}</p>
                                                                </a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a class="nav-link" onClick={this.getNextWeek}><i
                                                                    class="now-ui-icons arrows-1_minimal-right"
                                                                    aria-hidden="true"></i></a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <Form>
                                                                    <InputGroup className="no-border">
                                                                        <Input
                                                                            name="searchInput"
                                                                            defaultValue={this.state.searchInput}
                                                                            onChange={(event) => {
                                                                                this.handleChange(event);
                                                                            }}
                                                                            id="input1"
                                                                            type="text"
                                                                            placeholder="Search person responsible for follow-up"
                                                                        />
                                                                        <InputGroupAddon addonType="append">
                                                                            <InputGroupText>
                                                                                <i className="now-ui-icons ui-1_zoom-bold"/>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </Form>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </nav>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-info">
                                        <tr>
                                            <th>Corrective Ref</th>
                                            <th>Participant Name</th>
                                            <th>Date of Corrective Action</th>
                                            <th>Recommendation</th>
                                            <th>Action</th>
                                            <th>Priority</th>
                                            <th>Risk Rating</th>
                                            <th>Follow-up Person</th>
                                            <th>Due Date</th>
                                            <th className="text-truncate" style={{"maxWidth": "150px"}}>Status</th>
                                            <th>Outcome Evaluation</th>
                                            <th>&nbsp;Options</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {
                                            this.state.incidents.map(element => {
                                                return (
                                                    <tr>
                                                        <td> {element.id} </td>
                                                        <td> {element.tbluser.firstname} {element.tbluser.lastname} {!element.tblparticipant ? element.otherNotParticipant : ''} </td>
                                                        <td> {moment(element.Proj2_incident.date).format('YYYY-MM-DD')} </td>
                                                        <td className="text-truncate"
                                                            style={{"maxWidth": "150px"}}> {element.issue} </td>
                                                        <td className="text-truncate"
                                                            style={{"maxWidth": "250px","minWidth":"120px"}}> {element.proposedCorrectiveAction} </td>
                                                        <td> {
                                                            element.priority == "insignificant" ?
                                                                <Badge id="badgeInsignificant" color="success"
                                                                       style={{marginLeft: -20}}>Insignificant</Badge>
                                                                : element.priority == "Minor" ?
                                                                    <Badge id="badgeMinor" color="success"
                                                                           style={{marginLeft: -20}}>Minor</Badge>
                                                                    : element.priority == "Moderate" ?
                                                                        <Badge id="badgeModerate" color="warning"
                                                                               style={{marginLeft: -20}}>Moderate</Badge>
                                                                        : element.priority == "Major" ?
                                                                            <Badge id="badgeMajor" color="warning"
                                                                                   style={{marginLeft: -20}}>Major</Badge>
                                                                            : element.priority == "Critical" ?
                                                                                <Badge id="badgeCritical" color="danger"
                                                                                       style={{marginLeft: -20}}>Critical</Badge>
                                                                                : element.priority

                                                        } </td>
                                                        <td> {
                                                            element.riskRating == "Low" ?
                                                                <Badge id="badgeRiskLow" color="success">Low</Badge>
                                                                : element.riskRating == "Medium" ?
                                                                    <Badge id="badgeRiskMedium"
                                                                           color="success">Medium</Badge>
                                                                    : element.riskRating == "High" ?
                                                                        <Badge id="badgeRiskHigh"
                                                                               color="warning">High</Badge>
                                                                        : element.riskRating == "Extreme" ?
                                                                            <Badge id="badgeRiskExtreme"
                                                                                   color="danger">Extreme</Badge>
                                                                            : element.riskRating
                                                        } </td>
                                                        <td> {element.tbluser.firstname + " " + element.tbluser.lastname} </td>
                                                        <td> {element.completionDate} </td>
                                                        <td className="text-truncate"
                                                            style={{"maxWidth": "150px"}}> {element.status} </td>
                                                        <td className="text-truncate"
                                                            style={{"maxWidth": "150px"}}> {element.outcomeEvaluation} </td>
                                                        <td>

                                                            <Button
                                                                color="info"
                                                                className="btn btn-info"
                                                                size="sm"
                                                                onClick={() => {
                                                                    this.state.selectedCorrectiveActionId = element.id;
                                                                    this.state.selectedItem = element.Proj2_incident.incidentId;
                                                                    this.state.selectedIssue = element.issue;
                                                                    this.state.selectedProposedCorrectiveAction = element.proposedCorrectiveAction;
                                                                    this.state.selectedPriority = element.priority;
                                                                    this.state.selectedRiskRating = element.riskRating;
                                                                    this.state.selectedPersonResponsible = `${element.tbluser.firstname} ${element.tbluser.lastname}`;
                                                                    this.state.selectedCompletionDate = element.completionDate;
                                                                    this.state.selectedStatus = element.status;
                                                                    this.state.selectedOutcomeEvaluation = element.outcomeEvaluation;

                                                                    this.setState({showViewModal: true});

                                                                }
                                                                }>
                                                                {/* <i className="now-ui-icons ui-1_zoom-bold" /> */}
                                                                View
                                                            </Button>

                                                            {Number(GlobalFunctions.extractToken().ACCESS_LEVEL) <= 5
                                                                ?
                                                            <Button
                                                                color="warning"
                                                                className="btn btn-info"
                                                                size="sm"
                                                                onClick={() => {
                                                                    this.handleSignOut(element.Proj2_incident.incidentId);
                                                                }
                                                                }>
                                                                {/* <i className="now-ui-icons ui-1_zoom-bold" /> */}
                                                                Sign off
                                                            </Button> : ""}

                                                            {/*<Button*/}
                                                            {/*  color="warning"*/}
                                                            {/*  className="btn btn-info btn-icon-mini btn-neutral"*/}
                                                            {/*  size="sm"*/}
                                                            {/*  onClick={() => {*/}
                                                            {/*    this.handleDelete(element.id);*/}
                                                            {/*  }}*/}
                                                            {/*>*/}
                                                            {/*  /!* <i className="now-ui-icons ui-1_simple-remove" /> *!/*/}
                                                            {/*  Delete*/}
                                                            {/*</Button>*/}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default CorrectiveActionFilled;
