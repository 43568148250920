/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import IncidentRegister from "views/IncidentRegister.js";
import CorrectiveActionFilled from "views/CorrectiveActionFilled"
import ClosedIncident from "./views/ClosedIncident";
import IncidentReportNew from "./views/IncidentReportNew";

var dashRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "design_app",
        component: Dashboard,
        layout: "/admin",
        accessLevel: 5
    },
    {
        path: "/incident-report",
        name: "Incident report",
        icon: "design_image",
        component: IncidentReportNew,
        layout: "/admin",
        accessLevel: 7
    },
    {
        path: "/incident-register",
        name: "Incident register",
        icon: "ui-1_bell-53",
        component: IncidentRegister,
        layout: "/admin",
        accessLevel: 5
    },
    {
        path: "/corective-action-filled",
        name: "Corrective register",
        icon: "users_single-02",
        component: CorrectiveActionFilled,
        layout: "/admin",
        accessLevel: 5
    },
    {
        path: "/closed-incidents",
        name: "Closed Incidents",
        icon: "users_single-02",
        component: ClosedIncident,
        layout: "/admin",
        accessLevel: 5
    }
];



export default dashRoutes;
