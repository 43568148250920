import React from "react";
import axios from "axios";
import moment from "moment";
import GlobalFunctions from "../controllers/GlobalFunctions";
import API_URL from '../variables/API_URL';

class StasticalAnalysis extends React.Component {

    static incidentRegister() {
        const start = moment().startOf('year').format('MM-DD-YYYY');
        const end = moment().endOf('year').format('MM-DD-YYYY');
        var url = `${API_URL.url}/incidents/statistics?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}&start=${start}&end=${end}`;
        return axios.get(url).then(incident => {
            const data = incident.data;
            var result = [];
            for (let index = 1; index < 13; index++) {
                var month = index;
                var count = 0;
                data.forEach(element => {
                    if (month == Number(moment(element.date).format('MM'))) {
                        count++;
                    }
                });
                result.push(count);
            }
            console.log(incident.data);
            return incident.data;
        }).catch(error => {
            return [];
        })
    }
    static async returnIncidentRegisterVariables() {
        var incidentRegisterData;
        await this.incidentRegister().then(result => {
            console.log(result);
            incidentRegisterData = result;
        });
        const chartColor = "#FFFFFF";
        const dashboardShippedProductsChart = {
            data: (canvas) => {
                var ctx = canvas.getContext("2d");
                var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                gradientStroke.addColorStop(0, "#80b6f4");
                gradientStroke.addColorStop(1, chartColor);
                var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
                gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
                gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");
                return {
                    labels: [
                        "Jan",
                        "Feb",
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                        "Sep",
                        "Oct",
                        "Nov",
                        "Dec",
                    ],
                    datasets: [
                        {
                            label: "Incidents reported",
                            borderColor: chartColor,
                            pointBorderColor: chartColor,
                            pointBackgroundColor: "#2c2c2c",
                            pointHoverBorderColor: chartColor,
                            pointBorderWidth: 2,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 1,
                            pointRadius: 4,
                            fill: true,
                            backgroundColor: gradientFill,
                            borderWidth: 2,
                            data: incidentRegisterData
                        },
                    ],
                };
            },
            options: '',
        };

        const dashboardPanelChart = {
            data: (canvas) => {
                const ctx = canvas.getContext("2d");
                var chartColor = "#FFFFFF";
                var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
                gradientStroke.addColorStop(0, "#80b6f4");
                gradientStroke.addColorStop(1, chartColor);
                var gradientFill = ctx.createLinearGradient(0, 200, 0, 50);
                gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
                gradientFill.addColorStop(1, "rgba(255, 255, 255, 0.14)");

                return {
                    labels: [
                        "JAN",
                        "FEB",
                        "MAR",
                        "APR",
                        "MAY",
                        "JUN",
                        "JUL",
                        "AUG",
                        "SEP",
                        "OCT",
                        "NOV",
                        "DEC",
                    ],
                    datasets: [
                        {
                            label: "Incidents reported",
                            borderColor: chartColor,
                            pointBorderColor: chartColor,
                            pointBackgroundColor: "#2c2c2c",
                            pointHoverBackgroundColor: "#2c2c2c",
                            pointHoverBorderColor: chartColor,
                            pointBorderWidth: 1,
                            pointHoverRadius: 7,
                            pointHoverBorderWidth: 2,
                            pointRadius: 5,
                            fill: true,
                            backgroundColor: gradientFill,
                            borderWidth: 2,
                            data: incidentRegisterData,
                        },
                    ],
                };
            },
            options: {
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        top: 0,
                        bottom: 0,
                    },
                },
                maintainAspectRatio: false,
                tooltips: {
                    backgroundColor: "#fff",
                    titleFontColor: "#333",
                    bodyFontColor: "#666",
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: "nearest",
                    intersect: 0,
                    position: "nearest",
                },
                legend: {
                    position: "bottom",
                    fillStyle: "#FFF",
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                fontColor: "rgba(255,255,255,0.4)",
                                fontStyle: "bold",
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                padding: 10,
                            },
                            gridLines: {
                                drawTicks: true,
                                drawBorder: false,
                                display: true,
                                color: "rgba(255,255,255,0.1)",
                                zeroLineColor: "transparent",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                                color: "rgba(255,255,255,0.1)",
                            },
                            ticks: {
                                padding: 10,
                                fontColor: "rgba(255,255,255,0.4)",
                                fontStyle: "bold",
                            },
                        },
                    ],
                },
            },
        };

        // return dashboardShippedProductsChart;
        return dashboardPanelChart;

    }
    // ...etc
}




export default StasticalAnalysis;