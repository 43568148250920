/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Spinner,
  Form,
  FormGroup,
  Input,
  Label,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalBody,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  UncontrolledCollapse
} from "reactstrap";
// tooltips
import { OverlayTrigger, Popover } from "react-bootstrap"

import Select from 'react-select';



// plugins
import axios from "axios";
import swal from '@sweetalert/with-react';
import moment from 'moment';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import GlobalFunctions from "../controllers/GlobalFunctions";
import API_URL from '../variables/API_URL';
import CorrectiveForm from "views/CorrectiveAction";

import { thead, tbody } from "variables/general";
import ReactDOM from "react-dom";
import IncidentReport from "./IncidentReport";
import IncidentReport2 from "./IncidentReport2";
import IncidentRegister from "./IncidentRegister";
import { useParams } from "react-router-dom";
import Form1 from "./Form1";
import Form2 from "./Form2";
import Form3 from "./Form3";
import Form4 from "./Form4";
import Form5 from "./Form5";

class IncidentReportNew extends React.Component {

}

function Greeting(props) {
  const stage = localStorage.getItem("form-stage");
  console.log(stage + " stage");
  if (stage == 0) {
    return <Form1 />;
  }else if(stage == 1) {
    return <Form2/>;
  }else if(stage == 2) {
    return <Form3/>;
  }else if(stage == 3) {
    return <Form4/>;
  }else if(stage == 4) {
    return <Form5/>;
  }

  return <Form1 />;
}

function UserGreeting(props) {
  return <h1>Welcome back!</h1>;
}

function GuestGreeting(props) {
  return <h1>Please sign up.</h1>;
}

export default Greeting;
