/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Spinner,
  Form,
  FormGroup,
  Input,
  Label,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Modal,
  ModalBody,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  UncontrolledCollapse
} from "reactstrap";
// tooltips
import { OverlayTrigger, Popover } from "react-bootstrap"

import Select from 'react-select';



// plugins
import axios from "axios";
import swal from '@sweetalert/with-react';
import moment from 'moment';

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import GlobalFunctions from "../controllers/GlobalFunctions";
import API_URL from '../variables/API_URL';
import CorrectiveForm from "views/CorrectiveAction";

import { thead, tbody } from "variables/general";

class AccessDenied extends React.Component {


  //RUNS WHEN COMPONENT IS MOUNTED
  componentDidMount = () => {
  //   this.getIncidents();
  }



  render() {
    return (
        <>

          <div className="content">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    ACCESS DENIED
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  }
}

export default AccessDenied;
