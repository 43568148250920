/*!

=========================================================
* Now UI Dashboard React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Badge,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
  Table
} from "reactstrap";

// tooltips
import { OverlayTrigger, Popover } from "react-bootstrap"

// external libraries
import axios from "axios";
import swal from '@sweetalert/with-react';
import NotificationAlert from "react-notification-alert";
import API_URL from '../variables/API_URL';


// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Datetime from 'react-datetime';
import { getJSDocImplementsTags } from "typescript";
import GlobalFunctions from "../controllers/GlobalFunctions";
import Spinner from "reactstrap/lib/Spinner";

class CorrectiveForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      clients: [],
      staffs: [],
      actionsToBeCompleted: [],
      control: [],
      actions: [],
      selectedParticipantId: "",
      selectedClientId: "",

      selectedReportedById: 0,
      selectedReportedToId: 0
    };
  }




  //RUNS WHEN COMPONENT IS MOUNTED
  componentDidMount = () => {

    this.getStaffs();
    this.getClients();

  }

  //GET INPUT TEXT FROM FORM
  setTitle = (event) => {

    this.setState({ [event.name]: event.value })

  }

  //CATEGORY POPOVER
  popoverHoverFocus = (event) => {
    return (
      <Popover id="popover-trigger-hover-focus" title="Popover bottom">
        <h1>hello hello</h1>
      </Popover>
    )
  }

  //GET ALL INCIDENT FROM DATABASE
  getClients = () => {

    var url = `${API_URL.url}/incidents/clients?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;

    axios.get(url).then(clients => {

      this.setState({ clients: clients.data });

    }).catch(error => {

    })
  }

  //GET ALL STAFFS FROM DATABASE
  getStaffs = () => {

    var url = `${API_URL.url}/incidents/staffs?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`;

    axios.get(url).then(staffs => {

      this.setState({ staffs: staffs.data });

    }).catch(error => {

    })
  }


  //SUBMIT INCIDENT FORM TO DATABASE
  handleSubmitIncident = (event) => {

    event.preventDefault();
    this.setState({ savingData: true })

    const infor = {
      "incidentId": this.state.selectedIncidentId,
      "actionsToBeCompleted": this.state.actionsToBeCompleted,
      "managementReport": {
        "status": this.state.status,
        "statusDue": this.state.date,
        "reportStatus": this.state.statusReport,
        "reportedBy": Number(this.state.selectedReportedById),
        "reportedTo": Number(this.state.selectedReportedToId),
        "reportedOn": this.state.reportedOn,
        "managementAssessment": this.state.managementAccess,
        "correctiveActionPerformedBy": Number(this.state.selectedCorrectiveActionPeformedBy),
        "incidentClosireApproval": this.state.incidentClosireApproval
      },
      "control": this.state.control,
      "actions": this.state.actions
    }

    console.log(infor);

    axios.post(`${API_URL.url}/corectiveActions/record?TOCKEN=${GlobalFunctions.extractToken().TOCKEN}`, infor)
      .then(res => {
        console.log(infor)
        this.setState({ savingData: false })
        swal("successfully submitted!", "Click okay to close", "success")
          .then(() => {

          })
      }).catch(error => {
        console.log(error);
        this.setState({ savingData: false })
        let errorObject = JSON.parse(JSON.stringify(error));
        swal(`${errorObject.message}!`, "submition failed, click okay and try again!", "error")
          .then(() => {
          })
      })
  }

  render() {
    return (
      <>
        {/* <PanelHeader size="sm" /> */}
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Corrective form</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={this.handleSubmitIncident}>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="participantSelect">Impact review</label>
                          <Input
                            //required
                            type="text"
                            name="ImpactReview"
                            onChange={event => this.setTitle(event.target)}

                          >
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label htmlFor="nameSelect">Rating</label>
                          <Input
                            //required
                            id="nameSelect"
                            type="text"
                            name="rating"
                          >
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Table responsive table borderless >

                        <tbody>
                          <tr>
                            <td>Actions to be completed</td>
                            <td>Timeframe</td>
                            <td>Who is responsible</td>
                            <td className="text-right">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="text-right pl-3">

                              <FormGroup>
                                <Input
                                  type="text"
                                  name="action"
                                  onChange={event => this.setTitle(event.target)}
                                />
                              </FormGroup>

                            </td>
                            <td>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="timeframe"
                                  onChange={event => this.setTitle(event.target)}
                                />
                              </FormGroup>

                            </td>
                            <td>

                              <FormGroup>
                                <Input
                                  type="text"
                                  name="responsibleStaff"
                                  onChange={event => this.setTitle(event.target)}
                                />
                              </FormGroup>

                            </td>
                            <td className="text-right">
                              <FormGroup>
                                <Button
                                  className="btn-round btn-block mt-0"
                                  color="warning"
                                  type="button"
                                  onClick={() => {
                                    var arr = this.state.actionsToBeCompleted;
                                    arr.push({ action: this.state.action, timeFrame: this.state.timeframe, responsibleStaff: this.state.responsibleStaff });
                                    this.setState({ actionsToBeCompleted: arr })

                                    console.log(arr);
                                  }
                                  }>
                                  +
                                  </Button>
                              </FormGroup>
                            </td>
                          </tr>

                          {
                            this.state.actionsToBeCompleted.map(element => {
                              return (
                                <tr>
                                  <td className="pl-4">
                                    {element.action}
                                  </td>
                                  <td>
                                    {element.timeFrame}
                                  </td>
                                  <td>
                                    {element.responsibleStaff}
                                  </td>
                                  <td>
                                    <FormGroup>
                                      <Button
                                        className="btn-round btn-block mt-0"
                                        color="warning"
                                        type="button"
                                        value={element.action + element.timeframe + element.responsibleStaff}
                                        onClick={(event) => {
                                          var arr = this.state.actionsToBeCompleted;
                                          const index = arr.findIndex(x => x.action + x.timeframe + x.responsibleStaff == event.target.value);
                                          if (index >= 0) {
                                            arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                          }
                                          this.setState({ actionsToBeCompleted: arr });
                                        }
                                        }>
                                        x
                                  </Button>
                                    </FormGroup>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </Row>

                    <Row>

                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Status</label>
                          <Input
                            //required
                            type="text"
                            name="status"
                            onChange={event => this.setTitle(event.target)}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Status date</label>
                          <Input
                            //required
                            type="date"
                            name="date"
                            onChange={event => this.setTitle(event.target)}

                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Status report</label>
                          <Input
                            //required
                            type="text"
                            name="statusReport"
                            onChange={event => this.setTitle(event.target)}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="participantSelect">Reported by</label>
                          <Input
                            //required
                            id="reportedBySelect"
                            type="select"
                            name="reportedBy"
                            onChange={event => this.setTitle(event.target)}
                            onClick={(event) => {
                              var reportedById = event.target.value;
                              this.setState({ selectedReportedById: reportedById })
                              console.log(this.state.selectedReportedById);
                            }}
                          >
                            <option value="">---Select reported by---</option>
                            {

                              this.state.staffs.map(element => {
                                return (
                                  <option
                                    value={element.id}
                                    key={element.id}>{element.firstname} {element.lastname}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label htmlFor="nameSelect">Management access</label>
                          <Input
                            //required
                            type="text"
                            name="managementAccess"
                            onChange={event => this.setTitle(event.target)}
                          >
                          </Input>
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="participantSelect">Reported to</label>
                          <Input
                            //required
                            id="reportedToSelect"
                            type="select"
                            name="reportedTo"
                            onChange={event => this.setTitle(event.target)}
                            onClick={(event) => {
                              var reportedToId = event.target.value;
                              this.setState({ selectedReportedToId: reportedToId })
                              console.log(this.state.selectedReportedToId);
                            }}
                          >
                            <option value="">---Select Reported to---</option>
                            {

                              this.state.staffs.map(element => {
                                return (
                                  <option
                                    value={element.id}
                                    key={element.id}>{element.firstname} {element.lastname}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="participantSelect">Corrective action perfomed by</label>
                          <Input
                            //required
                            id="correctiveActionPerformedSelect"
                            type="select"
                            name="correctiveActionPerformed"
                            onChange={event => this.setTitle(event.target)}
                            onClick={(event) => {
                              var correctiveActionPeformedBy = event.target.value;
                              this.setState({ selectedCorrectiveActionPeformedBy: correctiveActionPeformedBy })
                              console.log(this.state.selectedCorrectiveActionPeformedBy);
                            }}
                          >
                            <option value="">---Select Corrective action perfomed by---</option>
                            {

                              this.state.staffs.map(element => {
                                return (
                                  <option
                                    value={element.id}
                                    key={element.id}>{element.firstname} {element.lastname}</option>
                                )
                              })
                            }
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Reported on</label>
                          <Input
                            //required
                            type="date"
                            name="reportedOn"
                            onChange={event => this.setTitle(event.target)}

                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Incident closure approval</label>
                          <Input
                            //required
                            type="text"
                            name="incidentClosireApproval"
                            onChange={event => this.setTitle(event.target)}

                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Table responsive table borderless >

                        <tbody>
                          <tr>
                            <td>Control</td>
                            <td>Rate effectiveness</td>
                            <td className="text-right">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="text-right pl-3">

                              <FormGroup>
                                <Input
                                  type="text"
                                  name="effectivenessControl"
                                  onChange={event => this.setTitle(event.target)}
                                />
                              </FormGroup>

                            </td>
                            <td>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="effectivenessRate"
                                  onChange={event => this.setTitle(event.target)}
                                />
                              </FormGroup>

                            </td>
                            <td className="text-right">
                              <FormGroup>
                                <Button
                                  className="btn-round btn-block mt-0"
                                  color="warning"
                                  type="button"
                                  onClick={() => {
                                    var arr = this.state.control;
                                    arr.push({ control: this.state.effectivenessControl, effectivenessRate: this.state.effectivenessRate });
                                    this.setState({ control: arr })
                                    console.log(arr);
                                  }
                                  }>
                                  +
                                  </Button>
                              </FormGroup>
                            </td>
                          </tr>

                          {
                            this.state.control.map(element => {
                              return (
                                <tr>
                                  <td className="pl-4">
                                    {element.control}
                                  </td>
                                  <td>
                                    {element.effectivenessRate}
                                  </td>
                                  <td>
                                    <FormGroup>
                                      <Button
                                        className="btn-round btn-block mt-0"
                                        color="warning"
                                        type="button"
                                        value={element.control + element.effectivenessRate}
                                        onClick={(event) => {
                                          var arr = this.state.control;
                                          const index = arr.findIndex(x => x.control + x.effectivenessRate == event.target.value);
                                          if (index >= 0) {
                                            arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                          }
                                          this.setState({ control: arr });
                                        }
                                        }>
                                        x
                                  </Button>
                                    </FormGroup>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </Row>
                    <Row>
                      <Table responsive table borderless >

                        <tbody>
                          <tr>
                            <td>Action</td>
                            <td>Rate effectiveness</td>
                            <td className="text-right">&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="text-right pl-3">

                              <FormGroup>
                                <Input
                                  type="text"
                                  name="effectivenessAction"
                                  onChange={event => this.setTitle(event.target)}
                                />
                              </FormGroup>

                            </td>
                            <td>
                              <FormGroup>
                                <Input
                                  type="text"
                                  name="effectivenessRate"
                                  onChange={event => this.setTitle(event.target)}
                                />
                              </FormGroup>

                            </td>
                            <td className="text-right">
                              <FormGroup>
                                <Button
                                  className="btn-round btn-block mt-0"
                                  color="warning"
                                  type="button"
                                  onClick={() => {
                                    var arr = this.state.actions;
                                    arr.push({ action: this.state.effectivenessAction, effectivenessRate: this.state.effectivenessRate });
                                    this.setState({ actions: arr })
                                    console.log(arr);
                                  }
                                  }>
                                  +
                                  </Button>
                              </FormGroup>
                            </td>
                          </tr>

                          {
                            this.state.actions.map(element => {
                              return (
                                <tr>
                                  <td className="pl-4">
                                    {element.action}
                                  </td>
                                  <td>
                                    {element.effectivenessRate}
                                  </td>
                                  <td>
                                    <FormGroup>
                                      <Button
                                        className="btn-round btn-block mt-0"
                                        color="warning"
                                        type="button"
                                        value={element.control + element.effectivenessRate}
                                        onClick={(event) => {
                                          var arr = this.state.control;
                                          const index = arr.findIndex(x => x.control + x.effectivenessRate == event.target.value);
                                          if (index >= 0) {
                                            arr = arr.slice(0, index).concat(arr.slice(index + 1, arr.length));
                                          }
                                          this.setState({ control: arr });
                                        }
                                        }>
                                        x
                                  </Button>
                                    </FormGroup>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </Row>


                    <hr></hr>
                    {/* <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <Button className="btn-round btn-block mt-1" color="warning" type="button">
                            Attach file
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>File number 1</label>
                          <Input
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="2">
                        <FormGroup>
                          <br></br>
                          <Button className="btn-round btn-block mt-1" color="warning" type="button">
                            Remove file
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr></hr> */}
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          {
                            this.state.savingData ?

                              <Spinner color="info" style={{ width: "3rem", height: "3rem" }} type="grow" />

                              :
                              <Button className="btn-round" color="info" type="submit" value="Submit">
                                Submit corrective action form
                              </Button>
                          }

                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default CorrectiveForm;
