import React from "react";

class GlobalFunctions extends React.Component {
    static   extractToken() {
    //   https://incident-app.tarisa.com.au/?USERNAME=admin@hivelink.com.au&STAFF_ID=1&ACCESS_LEVEL=1&TOCKEN=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaW5nZXJQcmludCI6IjAzZTU2M2VmOWEwZmMyNTEzY2ZmNDFlZmUzYzczM2MwIiwiY29tcGFueUlkIjoiMSIsImVtYWlsIjoiYWRtaW5AaGl2ZWxpbmsuY29tLmF1Iiwic3RhZmZJZCI6IjEiLCJ1c2VySWQiOiIxIiwiYWNjZXNzX2xldmVsIjoiMSIsImlhdCI6MTY0Njk4NjA4NSwiZXhwIjoxNjQ3MDExMjg1fQ.4G11dutx8qHC5Yo-wVrtneoMsvoxC3Zqmp1Xjck2hVI
      // localStorage.setItem("token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiIxIiwiZW1haWwiOiJhZG1pbkBoaXZlbGluay5jb20uYXUiLCJzdGFmZklkIjoiMSIsImFjY2Vzc0xldmVsIjoiMSIsImNvbXBhbnlJZCI6IjEifQ.gYxs9xf9NYEUuJXl_ynbL0JzA02sP9_dxTJWi0kyfLM");
      //  localStorage.setItem("staff_id", 1);
      //  localStorage.setItem("access_level", 5);
      //  localStorage.setItem("mainsystem", "https://google.com");
      //
      //   const data1 = {
      //       TOCKEN: localStorage.getItem('token'),
      //       ACCESS_LEVEL: localStorage.getItem('access_level'),
      //       STAFF_ID: localStorage.getItem('staff_id'),
      //       mainsystem: localStorage.getItem('mainsystem')
      //   }
      //
      //   return data1;

        var params = {};
        var parser = document.createElement('a');
        parser.href = window.location.href;
        var query = parser.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split('=');
          params[pair[0]] = decodeURIComponent(pair[1]);
        }

        if (params.ACCESS_LEVEL != undefined) {
          localStorage.setItem("token", params.TOCKEN);
          localStorage.setItem("staff_id", params.STAFF_ID);
          localStorage.setItem("access_level", params.ACCESS_LEVEL);
          localStorage.setItem("mainsystem", params.mainsystem);
        }
        const data = {
          TOCKEN: localStorage.getItem('token'),
          ACCESS_LEVEL: localStorage.getItem('access_level'),
          STAFF_ID: localStorage.getItem('staff_id'),
          mainsystem: localStorage.getItem('mainsystem')
        }
    
        return params.ACCESS_LEVEL == undefined ? data : params
      }
    // ...etc
  }


  export default GlobalFunctions;