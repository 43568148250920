//  const url = 'http://localhost:5001'
// const url = 'https://rosterapi.tarisa.com.au/ams_incident_api/'
// const url = 'https://rosterapi.tarisa.com.au/incident_dev_api/'
//const url = 'https://rosterapi.tarisa.com.au/incident_app_api/'
const url = 'https://rosterapi.tarisa.com.au/incidentbackend/'
//const url = 'http://localhost:9002'
// const url = 'https://rosterapi.tarisa.com.au/incident_panasheagency_api/'
// const url = 'https://rosterapi.tarisa.com.au/ams_incident_demo_api/'
module.exports = {
  url, // MAIN API LINK
};